import { ApplicationController } from "../application_controller";
import AutoNumeric from 'autonumeric';
import BSN from "bootstrap.native";

export default class extends ApplicationController {
  static targets = ["invoices_table", "modal_currency_text", "modal_converted_amount_text",
  "modal_invoice_date", "modal_deal_exchange_rates", "modal_main_currency_rate", "modal_additional_currency_rate",
  "invoice_product_row_template", "invoice_products_table",
  "modal_row_number", "modal_currencies_radio", "invoice_currency_radio_template",
  "modal_amount_in_main_currency", "modal_amount_in_additional_currency",
  "modal_total_currency", "modal_total_in_main_currency", "modal_total_in_additional_currency"]


  connect() {
    if (this.hasInvoices_tableTarget) {
      this.loadInvoicesTable();
    }
  }

  // SEND EMAIL
  openEmailModalForm(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalMD(url);
  }

  // update deal service tr and sub table
  onEmailPostPutSuccess(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      // show notify
      window.vNotify.success({ text: data.notification_message, title: data.notification_title });
      window.layout_modal_md.hide();

      if (data.send_invoice == true) {
        const tab_target = document.getElementById("deal_invoices_table")
        if (tab_target) {
          const url = tab_target.getAttribute("data-deals--invoices-tab-content-url");
          fetch(url, {
            method: 'GET',
            headers: {
              'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
              'X-Requested-With': 'XMLHttpRequest',
              'Content-Type': 'application/html',
              'Accept': 'application/html'
            },
            credentials: 'same-origin'
          }).then((response) => {
            if (response.status == 200) {
              response.text().then((content) => {
                tab_target.innerHTML = content;
              })
            }
          });
        }
      }
    }
  }

  // update deal service form
  onEmailPostPutError(event) {
    const [data, status, xhr] = event.detail;
    // modal set content
    window.layout_modal_md.setContent(data.form_content)
  }
  // END SEND EMAIL


  toggleInvoiceProductsTable(event) {
    event.preventDefault();
    let element_url = event.currentTarget.getAttribute('href');
    let table = document.querySelector(element_url);
    event.currentTarget.classList.toggle("collapsed");
    if (table) table.classList.toggle("show");
  }

  toggleInvoiceProductsButton(event) {
    event.preventDefault();
    let target = event.currentTarget;
    let dropdown = new BSN.Dropdown(target);
    dropdown.toggle();
  }

  updateInvoiceCurrencies(event){
    let currencies    = event.currentTarget.getAttribute("data-account-currencies");
    let li_template   = this.invoice_currency_radio_templateTarget.innerHTML;
    let currencies_ul = this.modal_currencies_radioTarget;

    currencies_ul.innerHTML = "";
    if (currencies) {
      JSON.parse(currencies).forEach(el =>{
        let li = document.createElement("li");
        let currency_class = (el["disabled"] == true ? "disabled" : "")
        let li_html = li_template.replace(/INVOICE_AMOUNT_CURRENCY_CODE/g, el["currency"]);
        li_html = li_html.replace(/INVOICE_AMOUNT_CURRENCY_CLASS/g, currency_class);
        li_html = li_html.replace(/\"INVOICE_AMOUNT_CURRENCY_INFO\"/g, JSON.stringify(el));

        li.classList.add("nav-item");
        li.innerHTML = li_html;
        currencies_ul.appendChild(li);
      });

      let currency = currencies_ul.querySelector("li:first-child input[type=radio]");
      currency.checked = true;

      this.updateInvoiceProductsRowCurrencies(currency);
    }
  }

  updateCurrencyFields(event) {
    const currency = event.currentTarget;

    this.updateInvoiceProductsRowCurrencies(currency);
  }

  updateInvoiceProductsRowCurrencies(element) {
    let currency  = element.getAttribute("value");
    let info_data = element.getAttribute("data-currency-info");

    this.modal_currency_textTargets.forEach(el => {
      el.innerHTML = currency;
    })

    if (info_data){
      info_data = JSON.parse(info_data);

      if (info_data["is_main"] == true){
        this.modal_amount_in_main_currencyTargets.forEach(el => {
          el.classList.add("hidden");
        })

        this.modal_total_in_main_currencyTarget.classList.add("hidden");
      } else {
        this.modal_amount_in_main_currencyTargets.forEach(el => {
          el.classList.remove("hidden");
        })

        this.modal_total_in_main_currencyTarget.classList.remove("hidden");
      }

      if (info_data["is_additional"] == true){
        this.modal_amount_in_additional_currencyTargets.forEach(el => {
          el.classList.add("hidden");
        })

        this.modal_total_in_additional_currencyTarget.classList.add("hidden");
      } else {
        this.modal_amount_in_additional_currencyTargets.forEach(el => {
          el.classList.remove("hidden");
        })

        this.modal_total_in_additional_currencyTarget.classList.remove("hidden");
      }

    }

    this.setInvoiceCurrencyRate();
  }

  onFormFieldChange(event) {
    const tr                     = event.currentTarget.closest("tr");
    const quantity_input         = tr.querySelector("input.invoice_product_quantity");
    const price_input            = tr.querySelector("input.invoice_product_price");
    const amount_text            = tr.querySelector("span.invoice_product_amount");
    const amount_main_text       = tr.querySelector("span.invoice_product_main_converted_amount");
    const amount_additional_text = tr.querySelector("span.invoice_product_additional_converted_amount");

    const main_rate_value        = this.modal_main_currency_rateTarget.value;
    const additional_rate_value  = this.modal_additional_currency_rateTarget.value;

    let price_value = 0, amount_value = 0, amount_main_value = 0, amount_additional_value = 0;

    if (!quantity_input || !price_input)
      return;

    price_value = parseFloat(price_input.value.replace(",", '.').replace(/[^\d.-]/g, ''));
    amount_value = quantity_input.value * price_value;

    amount_main_value       = amount_value * main_rate_value;
    amount_additional_value = amount_value * additional_rate_value;

    amount_text.innerHTML            = this.setFormat(amount_value);
    amount_main_text.innerHTML       = this.setFormat(amount_main_value);
    amount_additional_text.innerHTML = this.setFormat(amount_additional_value);

    this.updateInvoiceTotalRow();
  }


  onDateChange(event) {
    this.loadExchangeRates();
  }

  addNewInvoiceProductRow(event) {
    event.preventDefault();

    const currency       = this.modal_currencies_radioTarget.querySelector("li input:checked");
    const currency_value = currency.value;
    let info_data        = currency.getAttribute("data-currency-info")
    let cell             = this.invoice_products_tableTarget.insertRow();
    let new_row          = this.invoice_product_row_templateTarget.innerHTML;
    let new_index = 0, converted_main_text_class = "", converted_additional_text_class = "" ;

    new_index = parseInt(this.invoice_products_tableTarget.getAttribute("data-rows_count"));

    if (info_data){
      info_data = JSON.parse(info_data);

      if (info_data["is_main"] == true){
        converted_main_text_class = "hidden";
      }
      if (info_data["is_additional"] == true){
        converted_additional_text_class = "hidden";
      }
    }

    new_row = new_row.replace(/NEW_INVOICE_PRODUCT_ROW_CURRENCY/g, currency_value);
    new_row = new_row.replace(/NEW_INVOICE_PRODUCT_ROW_INDEX/g, new_index);
    new_row = new_row.replace(/NEW_INVOICE_PRODUCT_ROW_CONVERTED_MAIN_TEXT_CLASS/g, converted_main_text_class);
    new_row = new_row.replace(/NEW_INVOICE_PRODUCT_ROW_CONVERTED_ADDITIONAL_TEXT_CLASS/g, converted_additional_text_class);

    cell.innerHTML = new_row;
    cell.setAttribute("data-row_index", new_index);
    this.invoice_products_tableTarget.setAttribute("data-rows_count", new_index + 1)

    this.setInvoiceProductsRowsNumbers();
  }

  removeInvoiceProductRow(event) {
    event.preventDefault();
    const tr = event.currentTarget.closest("tr");
    const tr_index = tr.getAttribute("data-row_index");
    let destroy_key = document.getElementById("invoice_invoice_products_attributes_" + tr_index + "__destroy");
    if (destroy_key)
      destroy_key.value = "true";
    tr.remove();

    this.setInvoiceProductsRowsNumbers();
    this.updateInvoiceTotalRow();
  }

  openModalForm(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalLG(url);
  }


  setInvoiceProductsRowsNumbers() {
    this.modal_row_numberTargets.forEach((el, index) => {
      el.innerHTML = index + 1;
    })
  }

  setInvoiceProductsRowsConvertedText() {
    const main_rate_value       = this.modal_main_currency_rateTarget.value;
    const additional_rate_value = this.modal_additional_currency_rateTarget.value;
    const rows                  = this.invoice_products_tableTarget.querySelectorAll("tr");

    rows.forEach(tr => {
      const amount                      = tr.querySelector("span.invoice_product_amount");
      const amount_main_converted       = tr.querySelector("span.invoice_product_main_converted_amount");
      const amount_additional_converted = tr.querySelector("span.invoice_product_additional_converted_amount");
      let amount_value = 0, amount_main_value = 0, amount_additional_value = 0;

      amount_value = parseFloat(amount.innerHTML.replace(",", '.').replace(/[^\d.-]/g, ''));
      amount_main_value       = amount_value * main_rate_value;
      amount_additional_value = amount_value * additional_rate_value;
      amount_main_converted.innerHTML       = this.setFormat(amount_main_value);
      amount_additional_converted.innerHTML = this.setFormat(amount_additional_value);
    })

    this.updateInvoiceTotalRow();
  }


  updateInvoiceTotalRow() {
    const main_rate_value       = this.modal_main_currency_rateTarget.value;
    const additional_rate_value = this.modal_additional_currency_rateTarget.value;
    let total_value = 0;
    this.invoice_products_tableTarget.querySelectorAll("span.invoice_product_amount").forEach((el) => {
      total_value += parseFloat(el.innerHTML.replace(",", '.').replace(/[^\d.-]/g, ''));
    })

    this.modal_total_currencyTarget.innerHTML = this.setFormat(total_value);
    this.modal_total_in_main_currencyTarget.querySelector("span:first-child").innerHTML       = this.setFormat(total_value * main_rate_value);
    this.modal_total_in_additional_currencyTarget.querySelector("span:first-child").innerHTML = this.setFormat(total_value * additional_rate_value);
  }

  setInvoiceCurrencyRate() {
    const currency = this.modal_currencies_radioTarget.querySelector("li input:checked").value;
    const rates = this.modal_deal_exchange_ratesTarget.value;

    if (rates) {
      let rates_json = JSON.parse(rates);
      this.modal_main_currency_rateTarget.value       = rates_json[0][currency] || 1.0
      this.modal_additional_currency_rateTarget.value = rates_json[1][currency] || 1.0
    }

    this.setInvoiceProductsRowsConvertedText();
  }


  setFormat(value) {
    if (!value)
      return 0;
    return AutoNumeric.format(value, {
      allowDecimalPadding: "floats",
      decimalPlaces: 2,
      digitGroupSeparator: " ",
      decimalCharacter: ".",
      decimalCharacterAlternative: ",",
      styleRules: {
        positive: "autoNumeric-positive"
      },
      minimumValue: 0
    });
  }

  async loadExchangeRates() {
    const url = this.modal_invoice_dateTarget.getAttribute("data-exchange-rates-url");
    const date = this.modal_invoice_dateTarget.value;

    fetch(url + "?date=" + date, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      credentials: 'same-origin'
    }).then((response) => {
      if (response.status == 200) {
        response.text().then((content) => {
          this.modal_deal_exchange_ratesTarget.value = content;

          this.setInvoiceCurrencyRate();
        })
      }
    });
  }


  async loadInvoicesTable() {
    const url = this.data.get("tab-content-url");
    const tab_target = this.invoices_tableTarget;

    fetch(url, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/html',
        'Accept': 'application/html'
      },
      credentials: 'same-origin'
    }).then((response) => {
      if (response.status == 200) {
        response.text().then((content) => {
          tab_target.innerHTML = content;
        })
      }
    });
  }


}