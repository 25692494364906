import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["modal_currency_text"]

  connect() {
  }

  openModalForm(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModal(url);
  }

  changeCurrency(event) {
    let currency = event.currentTarget.getAttribute("value");

    this.modal_currency_textTargets.forEach(el => {
      el.innerHTML = currency;
    });
  }

}