import BSN from "bootstrap.native"
import Tabulator from "tabulator-tables";
import AutoNumeric from 'autonumeric';
import vNotify from "../vanilla-notify"
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["index_table", "mapping_dropdown",
    "filter_deals", "filter_service_types",
    "filter_suppliers", "filter_deal_period",
  ]

  connect(){
    if (this.hasIndex_tableTarget) {
      const url = this.data.get("table-load-url");
      this.createIndexTable(url);
    }
  }


  openModalForm(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalMD(url);
  }


  onPostPutSuccess(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      // show notify
      window.vNotify.success({ text: data.notification_message, title: data.notification_title });

      // update row data
      const rowData = data.data
      const mainTable = document.getElementById("supplier_payments_table")
      const mainTabulator = mainTable.tabulator
      const childTableId = "supplier_payments_table_" + rowData.id;
      const row = mainTabulator.getRow(rowData.id)
      row.update(rowData)

      // show child table
      const rowCell = row.getCell("deal_services__payments_transactions_link");
      this.showTransactionsTable(rowCell.getElement())

      // hide form modal
      if (data.save_and_new === true || data.save_and_new === "true") {
        // modal set content
        window.layout_modal_md.setContent(data.form_content)
      } else {
        window.layout_modal_md.hide();
      }

    }
  }

  onPostPutError(event) {
    const [data, status, xhr] = event.detail;
    window.layout_modal_md.setContent(data.form_content)
  }


  filterTable() {
    this.setFilterToTable(false);
  }

  mappingOpen(event) {
    if (this.hasMapping_dropdownTarget) {
      const mapping_dropdown = this.mapping_dropdownTarget;
      new BSN.Dropdown(mapping_dropdown).toggle();
    }
  }

  mappingNotCloseClick(event) {
    event.stopPropagation();
  }

  toggleTransactions(event) {
    this.toggleTransactionsTable(event.currentTarget)
  }

  toggleTransactionsTable(div_target) {
    let tr = div_target.closest('.tabulator-row')
    let icon = div_target.querySelector('i')
    if (tr) tr.classList.toggle("show-child-table")
    if (icon) {
      if (icon.classList.contains("fa-angle-down")) {
        icon.classList.remove("fa-angle-down")
        icon.classList.add("fa-angle-up")
      } else {
        icon.classList.remove("fa-angle-up")
        icon.classList.add("fa-angle-down")
      }
    }
  }

  showTransactionsTable(div_target) {
    let tr = div_target.closest('.tabulator-row')
    let icon = div_target.querySelector('i')
    if (!tr.classList.contains("show-child-table")) tr.classList.toggle("show-child-table")
    if (icon) {
      if (icon.classList.contains("fa-angle-down")) {
        icon.classList.remove("fa-angle-down")
        icon.classList.add("fa-angle-up")
      } else {
        icon.classList.remove("fa-angle-up")
        icon.classList.add("fa-angle-down")
      }
    }
  }

  setFilterToTable(is_mapping) {
    let table = this.index_tableTarget.tabulator;
    let filters = []

    // let search_data = []
    if (this.hasFilter_dealsTarget) {
      filters.push({
        field: "deal_ids",
        type: "=",
        value: this.filter_dealsTarget.choices.getValue(true)
      })
    }

    if (this.hasFilter_deal_periodTarget) {
      filters.push({
        field: "deal_period",
        type: "=",
        value: this.filter_deal_periodTarget.value
      })
    }

    if (this.hasFilter_service_typesTarget) {
      filters.push({
        field: "service_type_ids",
        type: "=",
        value: this.filter_service_typesTarget.choices.getValue(true)
      })
    }

    if (this.hasFilter_suppliersTarget) {
      filters.push({
        field: "supplier_ids",
        type: "=",
        value: this.filter_suppliersTarget.choices.getValue(true)
      })
    }

    if (filters.length) {
      table.setFilter(filters);
    }
  }


  async createIndexTable(url) {
    const columns = this.data.get("table-columns");
    const table_target = this.index_tableTarget;

    const default_props = super.tabulatorDefaultProps
    const height = document.getElementById('content').clientHeight;
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");

    let addTransactions = this.addTransactionsSubTable
    let transactions_columns = this.transactionsColumns()
    transactions_columns = transactions_columns[super.appLocale]

    let table_props = {
      virtualDomBuffer: 4000,
      virtualDomHoz: false,
      index: "id",
      height: (height - 180) + "px",
      persistenceID: "supplier_payments_table",
      movableColumns: true,
      // columns
      columns: JSON.parse(columns),

      // sort
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],

      // pagination
      pagination: "remote",
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      paginationSizeSelector: [10, 25, 50],
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> ",
      rowFormatter: function (row) {
        addTransactions(row, transactions_columns);
      }
    }

    table_props = Object.assign(default_props, table_props);
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }

  addTransactionsSubTable(row, transactions_columns) {
    const transactions_data = row.getData().deal_services__payments_transactions
    const tableId = "supplier_payments_table_" + row.getData().id;
    const childTable = document.getElementById(tableId)

    if (childTable) {
      const childTabulator = childTable.tabulator
      childTabulator.setData(transactions_data)
    } else {
      //create and style holder elements
      let holderEl = document.createElement("div");
      holderEl.classList = "child-table supplier-payments-table ml-auto mr-20";
      holderEl.style.padding = "0 0 10px 20px";
      let tableEl = document.createElement("div");
      tableEl.classList = "table table-striped";
      tableEl.setAttribute("id", tableId);
      holderEl.appendChild(tableEl);

      row.getElement().appendChild(holderEl);

      if (transactions_data) {
        tableEl.tabulator = new Tabulator(tableEl, {
          minHeight: 300,
          layout: "fitDataTable",
          data: transactions_data,
          headerSort: true,
          columns: transactions_columns
        })
      }
    }
  }


  transactionsColumns() {
    const columns = {
      "ru": [{
        title: "№",
        field: "index",
        formatter: "plaintext",
        headerSort: false,
        width: 50
      },
      {
        title: "Инвойс",
        field: "invoice_number",
        formatter: "plaintext",
        headerSort: false,
        width: 120
      },
      {
        title: "Дата",
        field: "transaction_date",
        formatter: "plaintext",
        headerSort: true,
        width: 150
      },
      {
        title: "Реквизиты",
        field: "account",
        formatter: "plaintext",
        headerSort: false,
        width: 150
      },
      {
        title: "Сумма",
        field: "amount",
        formatter: "html",
        headerSort: false,
        hozAlign: "right",
        width: 200
      },
      {
        title: "Статус",
        field: "status",
        formatter: "plaintext",
        headerSort: false,
        width: 120
      },
      {
        title: "",
        field: "action_links",
        formatter: "html",
        headerSort: false,
        hozAlign: "right",
        width: 120
      }],
      "en": [{
        title: "№",
        field: "index",
        formatter: "plaintext",
        headerSort: false,
        width: 50
      },
      {
        title: "Invoice",
        field: "invoice_number",
        formatter: "plaintext",
        headerSort: false,
        width: 120
      },
      {
        title: "Date",
        field: "transaction_date",
        formatter: "plaintext",
        headerSort: true,
        width: 150
      },
      {
        title: "Account",
        field: "account",
        formatter: "plaintext",
        headerSort: false,
        width: 150
      },
      {
        title: "Amount",
        field: "amount",
        formatter: "html",
        headerSort: false,
        hozAlign: "right",
        width: 200
      },
      {
        title: "Status",
        field: "status",
        formatter: "plaintext",
        headerSort: false,
        width: 120
      },
      {
        title: "",
        field: "action_links",
        formatter: "html",
        headerSort: false,
        hozAlign: "right",
        width: 120
      }]
    }
    return columns
  }

}